import {
    FieldGuesser, 
    ShowGuesser
  } from "@api-platform/admin";
  import {    
    SingleFieldList,
    ChipField,    
    ReferenceArrayField,
} from 'react-admin';

export const CategoryShow = props => (
    <ShowGuesser {...props} title="Detalles de la Categoría">
        <FieldGuesser source={"name"} label="Nombre"/>
        <FieldGuesser source={"priority"} label="Orden"/>
        <ReferenceArrayField label="Productos" reference="products" source="products">
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
        
       
    </ShowGuesser>
);

/*
<InputGuesser source={"categories"} />
 <InputGuesser source={"imagesProds"} />
*/