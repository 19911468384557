import {
    ListGuesser,
    FieldGuesser,
  } from "@api-platform/admin";

export const CategoryList = props => (
    <ListGuesser {...props} title="Listado Categoría">
        <FieldGuesser source={"name"} label="Nombre"/>
        <FieldGuesser source={"priority"} label="Orden"/>        
    </ListGuesser>
);
