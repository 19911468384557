import {
    ListGuesser,
    FieldGuesser, 
  } from "@api-platform/admin";
  import {    
    SingleFieldList,
    ChipField,    
    ReferenceArrayField,
} from 'react-admin';

export const ProductList = props => (
    <ListGuesser {...props} title="Listado de Productos">
        <FieldGuesser source={"name"} label="Nombre"/>
        <FieldGuesser source={"description"} label="Descricción"/>
        <ReferenceArrayField label="Categorías" reference="prod_category_menus" source="categories">
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
    </ListGuesser>
);

/*
        <FieldGuesser source={"categories"} />
        <FieldGuesser source={"imagesProds"} />
        */