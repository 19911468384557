import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import { ProductList } from "./components/model/products/ProductList";
import { ProductEdit } from "./components/model/products/ProductEdit";
import { ProductShow } from "./components/model/products/ProductShow";
import { CategoryList } from "./components/model/categoryCarte/CategoryList";
import { CategoryShow } from "./components/model/categoryCarte/CategoryShow"; 
import { CategoryEdit } from "./components/model/categoryCarte/CategoryEdit";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";

export default () => (
  <HydraAdmin dashboard={Dashboard} authProvider={authProvider} entrypoint="https://hoster.dprimeramano.com/public/api" title="ssss" >
    <ResourceGuesser name="products"  list={ProductList} edit={ProductEdit} show={ProductShow}/>
    <ResourceGuesser name="prod_category_menus" list={CategoryList} edit={CategoryEdit} show={CategoryShow} />
  </HydraAdmin>
);