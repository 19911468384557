import {
    EditGuesser,
    InputGuesser, 
  } from "@api-platform/admin";
  import {      
    SelectArrayInput ,
    ReferenceArrayInput
} from 'react-admin';

export const ProductEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"name"} label="Nombre"/>
        <InputGuesser source={"description"} label="Descricción"/>
        <ReferenceArrayInput label="Categorías" reference="prod_category_menus" source="categories">
            <SelectArrayInput optionText="name" />            
        </ReferenceArrayInput>
    </EditGuesser>
);

/*
<InputGuesser source={"categories"} />
 <InputGuesser source={"imagesProds"} />
*/