import {
    FieldGuesser, 
    ShowGuesser
  } from "@api-platform/admin";
  import {    
    SingleFieldList,
    ChipField,    
    ReferenceArrayField,
} from 'react-admin';

export const ProductShow = props => (
    <ShowGuesser {...props} title="Detalles del Producto">
        <FieldGuesser source={"name"} label="Nombre"/>
        <FieldGuesser source={"description"} label="Descricción"/>
        <ReferenceArrayField label="Categorías" reference="prod_category_menus" source="categories">
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
        
       
    </ShowGuesser>
);

/*
<InputGuesser source={"categories"} />
 <InputGuesser source={"imagesProds"} />
*/